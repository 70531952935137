<script setup>
import { boolean, mixed, object, string } from 'yup';
import { reactive, useNuxtApp, useRuntimeConfig, ref } from '#imports';
import { Form as VeeForm } from 'vee-validate';

import useFile from '@/composables/useFile';
import { useMessagePopup } from '@/composables/useMessagePopup';
import { useFormHelp } from '@/composables/useFormHelp';
import { usePolicePopup } from '@/composables/usePolicePopup';
import { useWaitRequest } from '@/composables/useWaitRequest';

import { useFeedbackStore } from '@/stores/feedback';

import { police } from '@/configs/police';

import PopupCenter from '@/components/layout/popup/PopupCenter.vue';
import UiInput from '@/components/ui/form/UiInput.vue';
import UiFileMultiple from '@/components/ui/form/UiFileMultiple.vue';
import UiSelect from '@/components/ui/form/UiSelect.vue';
import UiCheckbox from '@/components/ui/form/UiCheckbox.vue';
import UiTextareaAutoHeight from '@/components/ui/form/UiTextareaAutoHeight.vue';
import UiButton from '@/components/ui/button/UiButton.vue';
import FormWrapper from '@/components/layout/forms/FormWrapper.vue';
import { YandexSmartCaptcha } from '@gladesinger/vue3-yandex-smartcaptcha';

const props = defineProps({
  closeModal: {
    type: Function,
    default: undefined,
  },
});

const fileComposable = useFile();
const { $tp } = useNuxtApp();
const { openMessagePopup } = useMessagePopup();
const { openPolicePopup } = usePolicePopup();
const formHelpComposable = useFormHelp();
const feedbackStore = useFeedbackStore();
const { requestInProgress, waitRequest } = useWaitRequest();

const configRuntime = useRuntimeConfig();
const yaCaptchaFeedbackRef = ref(null);
let formActions = null;

//<editor-fold desc="Form">
const formData = reactive({
  username: '',
  email: '',
  topic: null,
  comment: '',
  files: null,
  policy: false,
});

const themeList = [
  { id: 0, name: 'О продукте' },
  { id: 1, name: 'Регистрация' },
  { id: 2, name: 'Восстановление пароля' },
  { id: 3, name: 'Отписка от рассылок' },
  { id: 4, name: 'Заблокированная учетная запись' },
  { id: 5, name: 'Предложения и пожелания' },
  { id: 6, name: 'Другие вопросы по сайту' },
];
function sendForm(values, actions) {
  formActions = {
    values: values,
    actions: actions,
  };

  yaCaptchaFeedbackRef.value.execute();
}

function sendFormWithCaptcha(token) {
  yaCaptchaFeedbackRef.value.reset();

  const data = {
    username: formActions.values.username,
    email: formActions.values.email,
    topic: formActions.values.topic.name,
    comment: formActions.values.comment,
    files: formActions.values.files,
    policy: formActions.values.policy,
    captcha: token,
  };

  waitRequest(() =>
    feedbackStore
      .sendFeedback(data)
      .then(() => {
        openMessagePopup({ text: 'Ваше обращение отправлено' });
        props.closeModal?.();
      })
      .catch(({ data }) => {
        formHelpComposable.checkErrors(data, formActions.actions.setError);
      }),
  );
}
// </editor-fold>

//<editor-fold desc="Validation rules">
const maxMessageLength = 501;
const accept = `${fileComposable.fileTypes.png},${fileComposable.fileTypes.jpg},${fileComposable.fileTypes.jpeg},${fileComposable.fileTypes.gif},${fileComposable.fileTypes.bmp},`;

const validationSchema = object({
  username: string().required().trim().maxStringLength(100),
  email: string().required().trim().isValidEmail(),
  topic: mixed().required(),
  comment: string()
    .required()
    .max(maxMessageLength - 1),
  files: mixed()
    .notRequired()
    .minFileListSizeKb(50)
    .maxFileListSize(fileComposable.getBitesFromMb(10))
    .maxFileLength(3)
    .fileListTypes([
      fileComposable.fileTypes.png,
      fileComposable.fileTypes.jpg,
      fileComposable.fileTypes.jpeg,
      fileComposable.fileTypes.gif,
      fileComposable.fileTypes.bmp,
    ]),
  policy: boolean().required().isTrue(),
});
// </editor-fold>
</script>

<template>
  <PopupCenter full-width-mobile>
    <template #default>
      <FormWrapper
        class="feedback-popup"
        heading="Обращение в службу поддержки"
        :description="`Напишите нам о проблеме и специалисты свяжутся с вами.<br> Ответ поступит на указанный адрес электронной почты.<br> Или позвоните нам на Горячую Линию <a href='tel:88002001954'>8 800 200-19-54</a> (звонок для абонентов России бесплатный)`"
        hint="Поля с * — обязательные для заполнения"
      >
        <template #content>
          <client-only>
            <YandexSmartCaptcha
              ref="yaCaptchaFeedbackRef"
              :site-key="configRuntime?.public?.recaptchaKey"
              :invisible="true"
              shield-position="bottom-right"
              @on-success="sendFormWithCaptcha"
            />
          </client-only>

          <VeeForm
            v-slot="{ isSubmitting }"
            :validation-schema="validationSchema"
            class="feedback-popup__form"
            @submit="sendForm"
          >
            <UiInput
              v-model="formData.username"
              name="username"
              label="Имя*"
              placeholder="Имя*"
              class="feedback-popup__field"
            />

            <UiInput
              v-model="formData.email"
              name="email"
              label="E-mail*"
              placeholder="E-mail*"
              class="feedback-popup__field"
            />

            <UiSelect
              v-model="formData.topic"
              :options="themeList"
              label="Тема обращения*"
              placeholder="Тема обращения*"
              show-errors
              name="topic"
              track-displayed-by="name"
              track-by="id"
              class="feedback-popup__field"
            />

            <UiTextareaAutoHeight
              v-model="formData.comment"
              label="Сообщение*"
              placeholder="Сообщение*"
              name="comment"
              :max-length="maxMessageLength"
              class="feedback-popup__field"
              auto-height
              :max-height="140"
              :min-height="59"
            />

            <UiFileMultiple
              v-model="formData.files"
              :accept="accept"
              name="files"
              class="feedback-popup__field feedback-popup__field--file"
              label="Перетащите один или несколько файлов в эту область или выберите их на устройстве"
              label-mobile="Перетащите один или несколько файлов в эту область или выберите их на устройстве"
              hint="Допустимый формат файла JPG, PNG, GIF, BMP (размер не менее 50 кБ и не более 10 мБ)"
              placeholder="Не более 3 файлов"
            />

            <div class="feedback-popup__field feedback-popup__field--checkbox">
              <UiCheckbox
                v-model="formData.policy"
                name="policy"
                class="feedback-popup__field"
              >
                <span
                  class="feedback-popup__font feedback-popup__font--checkbox"
                >
                  Я даю
                  <button
                    type="button"
                    @click="openPolicePopup(police.personalData)"
                  >
                    Согласие
                  </button>
                  {{
                    $tp(
                      'на обработку персональных данных и подтверждаю ознакомление с',
                    )
                  }}
                  <button
                    type="button"
                    @click="openPolicePopup(police.privacyPolicy)"
                  >
                    Политикой о конфиденциальности
                  </button>

                  {{ $tp('и') }}

                  <button
                    type="button"
                    @click="openPolicePopup(police.termsConditions)"
                  >
                    Пользовательским соглашением
                  </button>
                </span>
              </UiCheckbox>
            </div>

            <div class="feedback-popup__footer">
              <UiButton
                type="submit"
                class="feedback-popup__button feedback-popup__button--submit"
                text="Отправить"
                :is-disabled="isSubmitting || requestInProgress"
                @dblclick.prevent
              />
            </div>
          </VeeForm>
        </template>
      </FormWrapper>
    </template>
  </PopupCenter>
</template>

<style scoped lang="scss">
.feedback-popup {
  display: flex;
  flex-direction: column;
  margin-top: em(32);
  margin-bottom: em(32);
  width: em(608);

  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: em(24);
    margin-bottom: 0;
  }

  &__font {
    &--checkbox {
      font-size: em(13);
      line-height: 1.2;

      &:deep(button) {
        display: inline;
        font-weight: 700;
        transition: $time-default ease-out;
        transition-property: color;

        @include hover {
          color: $color-blue-1;
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__button {
    &--submit {
      width: 100%;
      margin-top: em(36);
    }
  }

  &__field {
    &--checkbox {
      margin-top: em(12);
    }

    &--file {
      margin-top: em(12);
    }
  }
}
</style>
